<template>
  <v-dialog
    v-model="dialog"
    max-width="375"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        Cambiar clave
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="body-1 pt-4">
        <v-form ref="form" class="ma-4" @submit.prevent="cambiar_clave()">
          <v-text-field
            v-model.trim="pass_old"
            :append-icon="show_pass_old ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_old ? 'text' : 'password'"
            label="Clave actual"
            tabindex="1"
            :rules="[$store.state.global_rules.required]"
            autofocus
            @click:append="show_pass_old = !show_pass_old"
          ></v-text-field>
          <v-text-field
            v-model.trim="pass_new"
            label="Clave nueva"
            hint="Mínimo 6 caracteres"
            tabindex="1"
            :rules="[rules.min, rules.pass_diff]"
            :append-icon="show_pass_new ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_new ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass_new = !show_pass_new"
          ></v-text-field>
          <v-text-field
            v-model.trim="pass_repeat"
            label="Repita su clave nueva"
            tabindex="1"
            :rules="[rules.pass_confirm]"
            :append-icon="show_pass_repeat ? 'fas fa-eye' : 'fas fa-eye-slash'"
            :type="show_pass_repeat ? 'text' : 'password'"
            validate-on-blur
            counter
            @click:append="show_pass_repeat = !show_pass_repeat"
          ></v-text-field>
          <v-btn
            color="primary"
            class="mt-8"
            type="submit"
            tabindex="1"
            :loading="load"
            block
          >
            Confirmar
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      load: false,
      show_pass_new: false,
      show_pass_old: false,
      show_pass_repeat: false,
      pass_new: '',
      pass_old: '',
      pass_repeat: '',
      rules: {
        min: value => value.length >= 6 || 'Mínimo 6 caracteres',
        pass_diff: value => {
          if (!this.admin) {
            return value != this.pass_old || 'Ingrese una clave diferente a la anterior' 
          }
          else {
            return true
          }
        },
        pass_confirm: value => value == this.pass_new || 'Las claves no coinciden'
      }
    }
  },
  props: {
    value: Boolean
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async cambiar_clave () {
      if (this.$refs.form.validate()) {
        this.show_pass_new = false
        this.show_pass_old = false
        this.show_pass_repeat = false
        this.load = true
        await this.$store.dispatch('cambiar_clave', {
          vieja: this.pass_old,
          nueva: this.pass_new
        })
          .then(() => {
            this.dialog = false
            this.$swal.fire({
              icon: 'success',
              title: 'Clave actualizada correctamente'
            })
          })
          .catch(error => {
            this.$swal.fire({
              title: 'Ocurrio un error al cambiar la clave',
              text: error.message,
              icon: 'error'
            })
          })
        this.load = false
      }
    },
    clear () {
      this.show_pass_new = false
      this.show_pass_old = false
      this.show_pass_repeat = false
      this.pass_new = ''
      this.pass_old = ''
      this.pass_repeat = ''
      this.$refs.form.resetValidation()
    }
  }
}
</script>