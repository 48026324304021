import config from '../../config'
import store from '../store'

export default {
  namespaced: true,
  state: {
    canchas: [],
    torneos: [],
    tipos_programacion: []
  },
  mutations: {
    set_canchas (state, payload) {
      state.canchas = payload
    },
    set_torneos (state, payload) {
      state.torneos = payload
    },
    set_tipos (state, payload) {
      state.tipos_programacion = payload
    }
  },
  actions: {
    async get_tipos ({ state, commit }) {
      if (state.tipos_programacion.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/tipos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) commit('set_tipos', data.data)
          else throw new Error(data.message)

        } catch (error) {
          commit('set_tipos', [])
          store.dispatch('show_swal', {
            icon: 'error',
            title: 'Ocurrio un error al obtener los tipos de programacion',
            text: error.message
          })
        }
      }
    },
    async generar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async verificar ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/existe?torneo=${fto.torneo}&tipo=${fto.tipo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async search ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/search?torneo=${fto.torneo}&tipo=${fto.tipo}&zona=${fto.zona}&equipo=${fto.equipo}&fecha=${fto.fecha}&cancha=${fto.cancha}&hora=${fto.hora}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async datos ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion?torneo=${fto.torneo}&tipo=${fto.tipo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async eliminar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async descargar ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/download?torneo=${fto.torneo}&tipo=${fto.tipo}&fecha=${fto.fecha}&date=${fto.date}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async next_fecha ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/fecha?torneo=${fto.torneo}&tipo=${fto.tipo}&fecha=${fto.fecha}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_canchas ({ commit }) {
      try {
        commit('set_canchas', [])
        const res = await fetch(`${config.BASE_URL}/programacion/canchas`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.token
          }
        })

        const data = await res.json()

        if (data.exito == 1) commit('set_canchas', data.data)
        else throw new Error(data.message)

      } catch (error) {
        store.dispatch('show_swal', {
          icon: 'error',
          title: 'Ocurrio un error al obtener las canchas',
          text: error.message
        })
      }
    },
    async nueva_cancha ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/canchas`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async editar_cancha ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/canchas/${payload.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async marcar_equipos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/marcarRE`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async cambiar ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async panoramica ({}, torneo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/panoramica?torneo=${torneo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async historico ({}, torneo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/historico?torneo=${torneo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_torneos ({ state, commit }) {
      if (state.torneos.length == 0) {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/torneos`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })
  
          const data = await res.json()
  
          if (data.exito == 1) commit('set_torneos', data.data)
          else throw new Error(data.message)

        } catch (error) {
          commit('set_torneos', [])
          store.dispatch('show_swal', {
            icon: 'error',
            title: 'Ocurrio un error al obtener los torneos',
            text: error.message
          })
        }
      }
    },
    async fixtures ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/fixtures?torneo=${payload.torneo}&tipo=${payload.tipo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async generar_fixture ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/fixture`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async eliminar_fixture ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/fixture`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async descargar_fixture ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/getFixture`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_resultados ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/resultados?torneo=${fto.torneo}&tipo=${fto.tipo}&fecha=${fto.fecha}&cancha=${fto.cancha}&numero=${fto.numero}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async set_resultados ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/resultados`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify({ partidos: payload })
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_tabla ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/tabla?torneo=${fto.torneo}&tipo=${fto.tipo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_resultados_sp ({}, torneo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/resultadosSP?torneo=${torneo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async set_resultados_sp ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/resultadosSP`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify({ partidos: payload })
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_fechas ({}, torneo) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/fechas?torneo=${torneo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async get_partidos_ascenso ({}, fto) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/partidos?torneo=${fto.torneo}&tipo=${fto.tipo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            }
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    },
    async generar_partidos_ascenso ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/programacion/partidos`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.token
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          resolve(data)

        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  }
}