export const TIPOS_ARCHIVO = [
  // pdf
  { extension: '.pdf', mime: 'application/pdf', icono: 'far fa-file-pdf', color: 'red darken-4', tipo: 'PDF' },
  // imagenes
  { extension: '.png', mime: 'image/png', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.jpg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.jpe', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.jif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.jfi', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.jpeg', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.jfif', mime: 'image/jpeg', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  { extension: '.webp', mime: 'image/webp', icono: 'far fa-image', color: 'light-blue darken-1', tipo: 'IMAGEN' },
  // documentos de texto
  { extension: '.txt', mime: 'text/plain', icono: 'far fa-file-alt', color: '', tipo: 'TEXTO' },
  { extension: '.doc', mime: 'application/msword', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOCUMENTO' },
  { extension: '.docx', mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOCUMENTO' },
  { extension: '.odt', mime: 'application/vnd.oasis.opendocument.text', icono: 'far fa-file-word', color: 'blue darken-4', tipo: 'DOCUMENTO' },
  // hojas de cálculo
  { extension: '.csv', mime: 'text/csv', icono: 'fas fa-file-csv', color: 'green darken-4', tipo: 'CSV' },
  { extension: '.xls', mime: 'application/vnd.ms-excel', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'EXCEL' },
  { extension: '.xlsx', mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'EXCEL' },
  { extension: '.ods', mime: 'application/vnd.oasis.opendocument.spreadsheet', icono: 'far fa-file-excel', color: 'green darken-4', tipo: 'EXCEL' },
  // video
  { extension: '.mp4', mime: 'video/mp4', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VIDEO' },
  { extension: '.avi', mime: 'video/x-msvideo', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VIDEO' },
  { extension: '.mpg', mime: 'video/mpeg', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VIDEO' },
  { extension: '.webm', mime: 'video/webm', icono: 'fas fa-film', color: 'amber darken-1', tipo: 'VIDEO' }
]

export function formatDate (date) {
  if (!date || date.length != 10) return null
  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

export function parseDate (date) {
  if (!date || date.length != 10) return null
  const [day, month, year] = date.split('/')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export async function getDims (base64) {
  return await new Promise((resolve) => {
    let img = new Image()
    img.src = base64
    img.onload = () => {
      resolve({
        ancho: img.width,
        alto: img.height
      })
    }
  })
}

export function getExtension (fileName) {
  if (!fileName) return ''
  return fileName.substring(fileName.lastIndexOf('.'), fileName.size)
}

export function getMime (fileName) {
  const tipo_archivo = TIPOS_ARCHIVO.find(a => a.extension == getExtension(fileName))
  if (tipo_archivo) return tipo_archivo.mime
  else return ''
}

export function getNombreObjArray (array, key, value, atributo) {
  const item = array.find(i => i[key] == value)
  if (item) {
    if (atributo) return item[atributo]
    else return item.nombre
  } else {
    return ''
  }
}

export function formatMoney (money) {
  if (!money && money != 0) return ''
  return new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(money)
}

export function getTipoArchivo (extension, atributo) {
  if (!extension) return ''
  return TIPOS_ARCHIVO.find(a => a.extension === extension)[atributo]
}

export function orderListBy (list, key, orden) {
  let desc = -1
  let asc = 1
  if (orden == 'desc') {
    desc = 1
    asc = -1
  }
  list.sort(function(a, b) {
    if(a[key] < b[key]) { return desc }
    if(a[key] > b[key]) { return asc }
    return 0
  })
}